// src/Login.js
import React, { useState } from "react";
import axios from "axios";

import { useNavigate } from "react-router-dom";
// import { useHistory } from "react-router-dom";
import "./Login.css";

const Login = () => {
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate(); // Hook for navigation
  // const history = useHistory();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(""); // Reset error message

    // Check if both fields are filled
    if (!userId || !password) {
      setError("Please fill out both fields.");
      return;
    }

    try {
      // Sending login request to the backend
      const response = await axios.post(
        "https://api.tankpower.online/api/login",
        {
          userId, // shorthand for userId: userId
          password, // shorthand for Password: password
        }
      );

      if (response.data.success) {
        sessionStorage.setItem("userId", userId);

        // Redirect to the dashboard or home page
        navigate("/Game");
      } else {
        setError(
          response.data.message ||
            "Login failed. Please check your credentials."
        );
      }
    } catch (err) {
      console.error("Error during login:", err);
      setError("Something went wrong. Please try again later.");
    }
  };

  return (
    <>
      <div class="background">
        <div class="shapes"></div>
        <div class="shapes"></div>
      </div>
      <form onSubmit={handleLogin}>
        <h3>Login Form</h3>
        <label for="user"> Username</label>
        <input
          type="text"
          placeholder="User ID"
          required
          value={userId}
          onChange={(e) => setUserId(e.target.value)}
        />
        <label for="psw">Password</label>
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        {error && (
          <div className="error" style={{ color: "red" }}>
            {error}
          </div>
        )}

        <button
          type="submit"
          style={{
            color: "white",
            background: "blue",
            padding: "10px 20px",
            borderRadius: "5px",
            border: "none",
          }}
        >
          Login
        </button>

        <div class="social">
          <div class="gg">
            <i class="bi bi-google"></i> Google
          </div>
          <div class="fb">
            <i class="bi bi-facebook"></i> Facebook
          </div>
        </div>
      </form>
    </>
  );
};

export default Login;
