// import React from "react";
// import ReactDOM from "react-dom/client";
// import "./index.css";
// import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import Login from "./Login";
// import Game from "./Test2";
// import App from "./App";
// import Home from "./home";
// import Test from "./Test";
// import Test2 from "./Test2";
// import reportWebVitals from "./reportWebVitals";
// import Test3 from "./Test3";

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <React.StrictMode>
//     {/* <App /> */}
//     {/* <Home /> */}
//     <Test2 />
//   </React.StrictMode>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
import React from "react";
import ReactDOM from "react-dom/client";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
// import { BrowserRouter as Router, Route, Routes } from "react-router-dom"; // Correct imports for v6
import "./index.css";
import Login from "./Login";
import Game from "./Game";
import App from "./App";
import Test from "./Test";
import Test2 from "./Test2";
import reportWebVitals from "./reportWebVitals";
import Test3 from "./Test3";
import Test4 from "./Test4";

// Create the root element for the app
const root = ReactDOM.createRoot(document.getElementById("root"));

// Render the app with router and routes
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        {/* Define all your routes here */}
        <Route path="/" element={<Game />} /> {/* Home page */}
        <Route path="/login" element={<Login />} /> {/* Login page */}
        <Route path="/game" element={<Game />} /> {/* Game page */}
        <Route path="/app" element={<App />} /> {/* App page */}
        <Route path="/test" element={<Test />} /> {/* Test page */}
        <Route path="/test2" element={<Test2 />} /> {/* Test2 page */}
        <Route path="/test3" element={<Test3 />} /> {/* Test3 page */}
        <Route path="/test4" element={<Test4 />} /> {/* Test3 page */}
      </Routes>
    </Router>
  </React.StrictMode>
);

// Measure performance (optional)
reportWebVitals();
