// import React, { useState, useEffect } from "react";
// import TapArea from "./components/TapArea";
// import Cards from "./components/Cards";
// import Popup from "./components/Popup";
// import TopInfoBar from "./components/TopInfoBar";

// function App() {
//   const [points, setPoints] = useState(0);
//   const [tapPower, setTapPower] = useState(1);
//   const [upgradeCost, setUpgradeCost] = useState(100);
//   const [totalTokens, setTotalTokens] = useState(100);
//   const [popupVisible, setPopupVisible] = useState(false);

//   const [cards, setCards] = useState([
//     { id: 1, level: 0, price: 10, baseReward: 5, currentReward: 6000 },
//     { id: 2, level: 0, price: 20, baseReward: 8, currentReward: 1200 },
//   ]);

//   const handleTap = () => {
//     setPoints(points + tapPower);
//   };

//   const upgradeTapPower = () => {
//     if (points >= upgradeCost) {
//       setPoints(points - upgradeCost);
//       setTapPower(tapPower + 1);
//       setUpgradeCost(upgradeCost * 2);
//     } else {
//       alert("Insufficient points!");
//     }
//   };

//   const togglePopup = () => {
//     setPopupVisible(!popupVisible);
//   };

//   return (
//     <div className="App">
//       <TopInfoBar
//         points={points}
//         tapPower={tapPower}
//         upgradeCost={upgradeCost}
//         totalTokens={totalTokens}
//       />
//       <TapArea handleTap={handleTap} />
//       <Cards
//         cards={cards}
//         totalTokens={totalTokens}
//         setTotalTokens={setTotalTokens}
//         setCards={setCards}
//       />
//       <Popup visible={popupVisible} togglePopup={togglePopup} cards={cards} />
//     </div>
//   );
// }

// export default App;

// src/App.js
import React, { useState, useEffect } from "react";
import "./App.css"; // Import your CSS here
import dailycombo from "./images/daily-combo.png";
import BinanceLogo from "./images/binance-logo.png";
import HamsterCoin from "./images/hamster-coin.png";
import DailyReward from "./images/daily-reward.png";
import DailyCipher from "./images/daily-cipher.png";
import MainCharacter from "./images/main-character.png";

const App = () => {
  // State variables
  const [points, setPoints] = useState(0);
  const [tapPower, setTapPower] = useState(1);
  const [upgradeCost, setUpgradeCost] = useState(100);
  const [totalTokens, setTotalTokens] = useState(100);
  const [showPopup, setShowPopup] = useState(false);
  const [cards, setCards] = useState([
    { id: 1, level: 0, price: 10, baseReward: 5, currentReward: 6000 },
    { id: 2, level: 0, price: 20, baseReward: 8, currentReward: 1200 },
  ]);
  const [formattedProfit, setFormattedProfit] = useState(0);

  // Function to upgrade tap
  const handleUpgrade = () => {
    if (points >= upgradeCost) {
      setPoints((prevPoints) => prevPoints - upgradeCost);
      setTapPower((prevPower) => prevPower + 1);
      setUpgradeCost((prevCost) => prevCost * 2);
    } else {
      alert("Insufficient points!");
    }
  };

  // Function to render cards
  const renderCards = () => {
    return cards.map((card) => (
      <div key={card.id} className="card">
        <h3>Card {card.id}</h3>
        <p>Level: {card.level}</p>
        <p>Current Reward: {card.currentReward} tokens/hour</p>
        <p>Upgrade Price: {card.price} tokens</p>
        <button onClick={() => upgradeCard(card.id)} style={{ color: "red" }}>
          Upgrade
        </button>
      </div>
    ));
  };

  // Function to upgrade a card
  const upgradeCard = (cardId) => {
    setCards((prevCards) =>
      prevCards.map((card) => {
        if (card.id === cardId && totalTokens >= card.price) {
          const newLevel = card.level + 1;
          const newPrice = card.price * 2;
          const newReward = card.baseReward * Math.pow(1.75, newLevel);
          setTotalTokens((prevTokens) => prevTokens - card.price);
          return {
            ...card,
            level: newLevel,
            price: newPrice,
            currentReward: newReward,
          };
        }
        return card;
      })
    );
  };

  // Update profit display
  const updateProfitDisplay = () => {
    const totalProfitPerHour = cards.reduce(
      (sum, card) => sum + card.currentReward,
      0
    );
    setFormattedProfit(totalProfitPerHour);
  };

  // Automatically add points every second
  useEffect(() => {
    const intervalId = setInterval(() => {
      setPoints((prevPoints) => prevPoints + formattedProfit / 3600);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [formattedProfit]);

  // Update formatted profit on cards change
  useEffect(() => {
    updateProfitDisplay();
  }, [cards]);

  const [activeTouches, setActiveTouches] = useState(0);

  // Function to handle tap
  const handleTap = (e) => {
    // Each tap increases points by tapPower
    setPoints((prevPoints) => prevPoints + tapPower * activeTouches);
  };

  // Handle touch start event
  const handleTouchStart = (e) => {
    // Prevent default to avoid triggering mouse events
    e.preventDefault();
    // Update the number of active touches
    setActiveTouches(e.touches.length);
  };

  // Handle touch end event
  const handleTouchEnd = () => {
    // Reset active touches when fingers are lifted
    setActiveTouches(0);
    handleTap(); // Call handleTap to add points for the final tap
  };

  return (
    <div
      className="bg-black flex justify-center"
      style={{
        background:
          "url(https://www.shutterstock.com/image-vector/3d-empty-studio-abstract-background-600nw-2431791911.jpg)",
      }}
    >
      <div className="w-full bg-black text-white h-screen font-bold flex flex-col max-w-xl">
        <div className="px-4 z-10">
          {/* User Info */}
          <div className="flex items-center space-x-2 pt-4">
            <div className="p-1 rounded-lg bg-[#1d2025]">
              {/* SVG Icon */}
              <svg
                fill="currentColor"
                className="text-[#d4d4d4]"
                height="24px"
                width="24px"
                viewBox="0 0 512 512"
              >
                <path d="M0,135.434c0,27.786,13.637,54.737,37.605,71.028c18.611-53.476,51.532-106.098,95.693-142.701 C76.535,26.245,0,66.968,0,135.434z"></path>
                <path d="M378.702,63.759c43.385,35.965,76.691,88.103,95.692,142.702C498.321,190.199,512,163.267,512,135.434 C512,66.961,435.457,26.251,378.702,63.759z"></path>
                <path d="M256.003,49.597c-0.012,0-0.031,0-0.044,0C141.939,49.634,53.684,193.404,53.684,302.856 c0,111.622,89.237,159.667,202.316,159.667c113.018,0,202.316-48.063,202.316-159.667 C458.316,193.417,370.064,49.597,256.003,49.597z"></path>
              </svg>
            </div>
            <div>
              <p className="text-sm">Nikandr (CEO)</p>
            </div>
          </div>
          {/* Silver Status */}
          <div className="flex items-center justify-between space-x-4 mt-1">
            <div className="flex items-center w-1/3">
              <div className="w-full">
                <div className="flex justify-between">
                  <p className="text-sm">Silver</p>
                  <p className="text-sm">
                    2 <span className="text-[#95908a]">/ 10</span>
                  </p>
                </div>
                <div className="flex items-center mt-1 border-2 border-[#43433b] rounded-full">
                  <div className="w-full h-2 bg-[#43433b]/[0.6] rounded-full">
                    <div
                      className="progress-gradient h-2 rounded-full"
                      style={{ width: "3.035%" }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            {/* Profit per hour and PPC */}
            <div className="flex items-center w-2/3 border-2 border-[#43433b] rounded-full px-4 py-[2px] bg-[#43433b]/[0.6] max-w-64">
              <img src={BinanceLogo} alt="Exchange" className="w-8 h-8" />
              <div className="h-[32px] w-[2px] bg-[#43433b] mx-2"></div>
              <div className="flex-1 text-center">
                <p className="text-xs text-[#85827d] font-medium">
                  Profit per hour
                </p>
                <div className="flex items-center justify-center space-x-1">
                  <img
                    src={HamsterCoin}
                    alt="Dollar Coin"
                    className="w-[18px] h-[18px]"
                  />
                  <p className="text-sm">{`+${formattedProfit.toFixed(2)}K`}</p>
                </div>
              </div>
              <div className="h-[32px] w-[2px] bg-[#43433b] mx-2"></div>
              <div className="flex-1 text-center">
                <p className="text-xs text-[#85827d] font-medium">PPC</p>
                <p className="text-sm">{tapPower}</p>
              </div>
            </div>
          </div>
          {/* Hamster Coins, Daily Reward, Daily Cipher */}
          <div className="flex justify-between mt-4">
            <div className="bg-grey w-1/3 rounded-lg p-2 flex flex-col items-center text-center">
              <img src={HamsterCoin} alt="Hamster Coin" className="w-8 h-8" />
              <p className="text-xs">Hamster Coins</p>
              <p className="text-lg">{points}</p>
            </div>
            <div className="bg-grey w-1/3 rounded-lg p-2 flex flex-col items-center text-center">
              <img src={DailyReward} alt="Daily Reward" className="w-8 h-8" />
              <p className="text-xs">Daily Reward</p>
              <p className="text-lg">10:14</p>
            </div>
            <div className="bg-grey w-1/3 rounded-lg p-2 flex flex-col items-center text-center">
              <img src={DailyCipher} alt="Daily Cipher" className="w-8 h-8" />
              <button onClick={() => setShowPopup(true)}>Open Popup</button>
              <p className="text-lg">05:14</p>
            </div>
          </div>
        </div>
        {/* Tap Area */}
        <div className="flex-grow flex justify-center items-center">
          <div
            id="tap-area"
            className="tap-area card bg-[#43433b] w-64 h-64 rounded-lg flex justify-center items-center relative cursor-pointer"
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
            onClick={handleTap} // For desktop click support
            style={{ borderRadius: "50%" }}
          >
            <img
              src={MainCharacter}
              alt="Main Character"
              className="w-64 h-64"
            />
          </div>
        </div>
        {/* Upgrade and Friends Section */}
        <div className="flex justify-between mt-4">
          <div className="bg-grey w-1/2 rounded-lg p-2 flex flex-col items-center text-center">
            <img src={dailycombo} alt="Daily Combo" className="w-8 h-8" />
            <button
              style={{ background: "transparent", color: "white" }}
              onClick={handleUpgrade}
            >
              Upgrade Tap
            </button>
            <p>
              Cost: <span>{upgradeCost}</span> points
            </p>
          </div>
          <div className="bg-grey w-1/2 rounded-lg p-2 flex flex-col items-center text-center">
            <img src={dailycombo} alt="Friends" className="w-8 h-8" />
            <p className="text-xs">Friends</p>
            <p className="text-lg">1</p>
          </div>
        </div>
      </div>

      {/* Popup */}
      {showPopup && (
        <div className="popup bg-black">
          <div className="popup-content">
            <span className="close" onClick={() => setShowPopup(false)}>
              ◄ Back
            </span>
            <h2>Cards inside Popup</h2>
            <div className="cards-container">{renderCards()}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default App;
