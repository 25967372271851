import React, { useEffect, useState } from "react";
import "./home.css"; // Import your CSS here
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Game = () => {
  const [userId, setUserId] = useState("");
  const navigate = useNavigate();
  const [totalPoints, setTotalPoints] = useState(0);
  const [coinsPerHour, setCoinsPerHour] = useState(0);
  const [profitPerTap, setProfitPerTap] = useState(1);
  const [upgradeCost, setUpgradeCost] = useState(0);
  const [cityCards, setCityCards] = useState([]);
  const [showQuests, setShowQuests] = useState(false);
  const [activeTouches, setActiveTouches] = useState(0);
  const [showUpgrade, setShowUpgrade] = useState(false);
  const [showCity, setShowCity] = useState(false);
  const [showFriends, setShowFriends] = useState(false);

  const [taskCards, setTaskCards] = useState([]);

  const fetchCityCards = () => {
    fetch("https://api.tankpower.online/api/city-cards")
      .then((response) => response.json())
      .then((data) => setCityCards(data))

      .catch((error) => console.error("Error fetching city cards:", error));
  };
  const fetchUserData = async () => {
    try {
      const response = await axios.post(
        "https://api.tankpower.online/api/userdata",
        {
          userId: userId,
        }
      );
      if (response.data && response.data.length > 0) {
        const userData = response.data[0]; // Access first item of the response
        setTotalPoints(userData.totalPoints);
        setCoinsPerHour(userData.coinsPerHour);
        setProfitPerTap(userData.profitPerTap);
        setUpgradeCost(userData.upgradeCost);
      } else {
        console.warn("User data is empty or not returned correctly");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  fetchUserData();
  const fetchTaskCards = () => {
    fetch("https://api.tankpower.online/api/get-tasks")
      .then((response) => response.json())
      .then((data) => setTaskCards(data))
      .catch((error) => console.error("Error fetching city cards:", error));

    // setTaskCards(taskCards);
    setTaskCards(taskCards || []); // Default to empty array if not present
  };

  useEffect(() => {
    // Retrieve session data
    const userId = sessionStorage.getItem("userId");

    if (!userId) {
      // alert(userId);
      navigate("/Login");
    } else {
      setUserId(userId); // Set the session userId
    }
    fetchUserData();
    fetchTaskCards();

    // fetchUserData();

    fetchCityCards();
  }, [navigate]);

  const handleLogout = () => {
    // Remove userId from sessionStorage
    sessionStorage.removeItem("userId");
    sessionStorage.removeItem("expiryTime"); // If you stored an expiry time

    // Redirect to login page after logout
    navigate("/Login"); // You can customize this
  };

  // const handleTap = async () => {
  //   const newPoints = totalPoints + profitPerTap * activeTouches;
  //   setTotalPoints(newPoints);

  //   try {
  //     await axios.post("http://localhost:5001/api/postuserdata", {
  //       userId: userId,
  //       points: profitPerTap * activeTouches,
  //     });
  //   } catch (error) {
  //     console.error("Error updating points:", error);
  //   }
  // };

  // Fetch data dynamically from the database and format without `toLocaleString()`
  const formatNumber = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  // const handleTaskCompletion = (points) => {
  //   setTotalPoints((prev) => prev + points);
  // };

  const copyReferralLink = () => {
    navigator.clipboard
      .writeText("http://example.com/referral?code=12345")
      .then(() => alert("Referral link copied to clipboard!"))
      .catch(() => alert("Failed to copy referral link."));
  };
  // Handle touch start event
  const handleTouchStart = (e) => {
    // Prevent default to avoid triggering mouse events
    e.preventDefault();
    // Update the number of active touches
    setActiveTouches(e.touches.length);
  };

  // Handle touch end event
  const handleTouchEnd = () => {
    // Reset active touches when fingers are lifted
    setActiveTouches(0);
    handleLogoClick(); // Call handleTap to add points for the final tap
  };

  // const handleLogoClick = async () => {
  //   const newPoints = totalPoints + profitPerTap * activeTouches; // Add profitPerTap to totalPoints
  //   setTotalPoints(newPoints); // Update frontend state
  //   alert(newPoints);
  //   alert(profitPerTap);
  //   alert(userId);
  //   try {
  //     // Update backend with the new total points
  //     const response = await axios.post(
  //       "http://localhost:5001/api/postuserdata",
  //       { userId: userId, newPoints: newPoints }
  //     );
  //     alert(userId);
  //     // alert(response.data);
  //     console.log("Backend response:", response.data);
  //   } catch (error) {
  //     console.error("Error updating total points:", error);
  //   }
  // };
  const handleUpgrade = async () => {
    if (totalPoints >= upgradeCost) {
      try {
        // Calculate new values
        const newTotalPoints = totalPoints - upgradeCost;
        const newProfitPerTap = profitPerTap + 1;
        const newUpgradeCost = upgradeCost * 2;

        // Send the updated values to the backend API
        const response = await axios.post(
          "https://api.tankpower.online/api/postUpdatePerClick",
          { newTotalPoints, newProfitPerTap, newUpgradeCost, userId }
        );

        // Check if the response is successful
        if (response.status === 200) {
          // Update local state only if the backend is successful
          setTotalPoints(newTotalPoints);
          setProfitPerTap(newProfitPerTap);
          setUpgradeCost(newUpgradeCost);
          alert("Upgrade successful!");
        } else {
          alert("Failed to upgrade. Please try again.");
        }
      } catch (error) {
        console.error("Error upgrading:", error);
        alert("Failed to upgrade. Please try again.");
      }
    } else {
      alert("Not enough coins!");
    }
  };

  const handleCityUpgrade = async (cardId) => {
    const card = cityCards.find((c) => c.id === cardId);

    if (totalPoints >= card.cost) {
      try {
        // Deduct the cost from total points
        setTotalPoints((prevTotalPoints) => prevTotalPoints - card.cost);

        // Increase the coins per hour
        setCoinsPerHour(
          (prevCoinsPerHour) => prevCoinsPerHour + card.profitIncrease
        );

        // Update the cityCards state with the new cost and current profit
        setCityCards((prevCards) =>
          prevCards.map((c) =>
            c.id === cardId
              ? {
                  ...c,
                  cost: c.cost * 2,
                }
              : c
          )
        );

        // Make the API call to update the backend database
        const response = await fetch(
          "https://api.tankpower.online/api/upgrade-city",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ id: cardId }),
          }
        );

        if (response.ok) {
          alert("City upgraded successfully");
          // Optionally, re-fetch data to ensure UI consistency
          fetchCityCards();
        } else {
          alert("Failed to upgrade city");
        }
      } catch (error) {
        console.error("Error upgrading card:", error);
      }
    } else {
      alert("Not enough coins!");
    }
  };
  const handleTaskCompletion = async (taskId) => {
    const task = taskCards.find((c) => c.taskId === taskId);

    try {
      // Deduct the cost from total points
      setTotalPoints((prevTotalPoints) => prevTotalPoints + task.points);
      // Update the cityCards state with the new cost and current profit
      setCityCards((prevCards) =>
        prevCards.map((c) =>
          c.taskId === taskId
            ? {
                ...c,

                isCompleted: 1,
              }
            : c
        )
      );
      // Make the API call to update the backend database
      const response = await fetch("https://api.tankpower.online/api/tasks", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ taskId: taskId }),
      });

      if (response.ok) {
        alert("Tasks upgraded successfully");
        // Optionally, re-fetch data to ensure UI consistency
        fetchTaskCards();
      } else {
        alert("Failed to upgrade Tasks");
      }

      // // Make the API call to update the backend database
      // const response = await fetch("http://localhost:5001/api/get-tasks", {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   body: JSON.stringify({ id: taskId }),
      // });

      // if (response.ok) {
      //   alert("Task upgraded successfully");
      //   // Optionally, re-fetch data to ensure UI consistency
      //   fetchCityCards();
      // } else {
      //   alert("Failed to upgrade Task");
      // }
    } catch (error) {
      console.error("Error upgrading Task:", error);
    }
  };

  const handleLogoClick = async () => {
    try {
      // Send POST request to the server

      // const newPoints = totalPoints + profitPerTap * activeTouches;
      const newPoints = profitPerTap * activeTouches;

      const response = await axios.post(
        "https://api.tankpower.online/api/postuserdata",
        {
          userId: userId,
          points: newPoints, // Ensure naming matches server expectations
        }
      );
      fetchUserData();
      setTotalPoints(totalPoints + newPoints);

      console.log("Backend response:", response.data);
    } catch (error) {
      console.error("Error updating total points:", error);
    }
  };

  return (
    <div className="container">
      <div className="header">
        <div className="title">
          <img
            alt="User  Avatar"
            height="30"
            src="http://storage.googleapis.com/a1aa/image/9exyhcIJk21CcCUftOefMhmv3DUg3F1jKSpUUM5tuln9bQlOB.jpg"
            width="30"
          />
          <span>Tank Power</span>
          <i className="fas fa-check-circle verified"></i>
        </div>
        <div className="menu">
          <i className="fas fa-bars" onClick={handleLogout}></i>
        </div>
      </div>
      <div className="content">
        <div className="coins">
          <img
            alt="Coin Icon"
            height="30"
            src="http://storage.googleapis.com/a1aa/image/fcyTVLclVByZCC9bEuPpg5tOe052qmtoJH1PWjkR2PuDHUpTA.jpg"
            width="30"
          />
          <span>Coins Per Hour</span>
          <span className="amount">{coinsPerHour}</span>
        </div>
        <div className="coins">
          <img
            alt="Coin Icon"
            height="30"
            src="http://storage.googleapis.com/a1aa/image/fcyTVLclVByZCC9bEuPpg5tOe052qmtoJH1PWjkR2PuDHUpTA.jpg"
            width="30"
          />
          {/* <input
            type="string"
            value={totalPoints}
            onChange={(e) => updateProfitPerTap(Number(e.target.value))}
          /> */}
          <span className="amount">{totalPoints}</span>
        </div>
        <div
          className="main-logo"
          // onClick={handleTap}
          onClick={handleLogoClick}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
        ></div>
        <div className="tap-me">Tap Me {upgradeCost}</div>
      </div>
      <div className="footer">
        <div className="icon" onClick={() => setShowUpgrade(true)}>
          <img
            alt="Improve Icon"
            height="30"
            src="http://storage.googleapis.com/a1aa/image/pne1MKusSu1ve0U5kaSKJtYOtjGIPsUaeyznX1RE4OtLOoSnA.jpg"
            width="30"
          />
          <span> Improve</span>
        </div>
        <div className="icon" onClick={() => setShowCity(true)}>
          <img
            alt="City Icon"
            height="30"
            src="http://storage.googleapis.com/a1aa/image/s2F6Wm8Ub6YVFtrQho8WHw3KoALnE1ZozlhHP7MgVZmxBV6E.jpg"
            width="30"
          />
          <span>City</span>
        </div>
        <div className="icon">
          <img
            alt="Mining Icon"
            height="30"
            src="http://storage.googleapis.com/a1aa/image/QLG5NeZHFVUnNi1nfptDRqe38t8S2gl4ewQRT4f1fG9lwBV6E.jpg"
            width="30"
          />
          <span>Home</span>
        </div>
        <div className="icon" onClick={() => setShowFriends(true)}>
          <img
            alt="Friends Icon"
            height="30"
            src="http://storage.googleapis.com/a1aa/image/reNj7fH1Usrh9UApHjTSNHM42fIDrJ1NZAFNfhHUO2cdcQlOB.jpg"
            width="30"
          />
          <span>Friends</span>
        </div>
        <div className="icon" onClick={() => setShowQuests(true)}>
          <img
            alt="Quests Icon"
            height="30"
            src="http://storage.googleapis.com/a1aa/image/hKfMbON1TevWeoSFc3rW9xECtLRgH4XAt0mHVArwq9FAOoSnA.jpg"
            width="30"
          />
          <span>Quests</span>
        </div>
      </div>
      {showUpgrade && (
        <div className="upgrade-container">
          <div className="back-button" onClick={() => setShowUpgrade(false)}>
            &larr;Back
          </div>{" "}
          <br></br>
          <br></br>
          <div className="upgrade-title">Upgrade Profit Per Tap</div>
          <div className="upgrade-info">
            Current Profit Per Tap: {profitPerTap}
            <br />
            Next Level Cost: {upgradeCost} Coins
            {/* <br />
            Next Profit Per Tap: {profitPerTap + 1} */}
          </div>
          <button className="upgrade-button" onClick={handleUpgrade}>
            Upgrade
          </button>
        </div>
      )}
      {showCity && (
        <div className="city-container">
          <div className="back-button" onClick={() => setShowCity(false)}>
            &larr;Back
          </div>{" "}
          <br></br>
          <br></br>
          <div className="city-title">Upgrade Profit Per Hour</div>
          {cityCards.map((card) => (
            <div className="card" key={card.id}>
              <div className="card-title">Upgrade Card {card.id}</div>
              <div className="card-info">
                Current Profit: {card.currentProfit}
                <br />
                Next Level Cost: {formatNumber(card.cost)} Coins
                <br />
                Next Profit Increase: {card.profitIncrease}
              </div>
              <button
                className="card-button"
                onClick={() => handleCityUpgrade(card.id)}
              >
                Upgrade
              </button>
            </div>
          ))}
        </div>
      )}
      {showFriends && (
        <div className="friends-container">
          <div className="back-button" onClick={() => setShowFriends(false)}>
            &larr;Back
          </div>{" "}
          <br></br>
          <br></br>
          <div className="friends-title">Refer a Friend</div>
          <div className="friends-info">
            Share this referral link with your friends:
          </div>
          <div className="referral-link">
            http://example.com/referral?code=12345
          </div>
          <button className="copy -button" onClick={copyReferralLink}>
            Copy Link
          </button>
        </div>
      )}
      {/* {showQuests && (
        <div className="quests-container">
          <div className="back-button" onClick={() => setShowQuests(false)}>
            &larr;Back
          </div>{" "}
          <br></br>
          <br></br>
          <div className="quests-title"> Complete Tasks to Earn Coins</div>
          {taskCards.map((task) => (
            <div className="task" key={task.taskId}>
              <div className="task-title">{task.taskName}</div>
              <div className="task-info">
                Complete this task to earn {task.points} coins.
              </div>
              <button
                className="task-button"
                disabled={task.isCompleted === 1}
                onClick={() => handleTaskCompletion(task.taskId)}
              >
                {task.isCompleted === 1 ? "Completed" : "Complete Task"}
              </button>
            </div>
          ))}
        </div>
      )} */}

      {showQuests && (
        // alert(taskCards),
        <div className="quests-container">
          <div className="back-button" onClick={() => setShowQuests(false)}>
            &larr;Back
          </div>{" "}
          <br></br>
          <br></br>
          <div className="quests-title">Complete Tasks to Earn Coins</div>
          {taskCards.map((task) => (
            <div className="task" key={task.taskId}>
              <div className="task-title"> {task.taskName}</div>
              <div className="task-info">
                Complete this task to earn {task.points} coins.
              </div>
              <button
                className="task-button"
                onClick={() => handleTaskCompletion(task.taskId)}
              >
                {task.isCompleted ? "Completed" : "Complete Task"}
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Game;
