import React, { useEffect, useState } from "react";
import "./home.css"; // Import your CSS here
import axios from "axios";

const Home = () => {
  const [totalPoints, setTotalPoints] = useState(0);
  const [coinsPerHour, setCoinsPerHour] = useState(0);
  const [profitPerTap, setProfitPerTap] = useState(0);
  const [upgradeCost, setUpgradeCost] = useState(0);
  const [cityCards, setCityCards] = useState([]);
  const [activeTouches, setActiveTouches] = useState(0);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get("http://localhost:5000/api/userdata");
        const { userPoints, cityCards } = response.data;

        setTotalPoints(userPoints.totalPoints);
        setCoinsPerHour(userPoints.coinsPerHour);
        setProfitPerTap(userPoints.profitPerTap);
        setUpgradeCost(userPoints.upgradeCost);
        setCityCards(cityCards);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  const handleTap = async () => {
    const newPoints = totalPoints + profitPerTap * activeTouches;
    setTotalPoints(newPoints);

    try {
      await axios.post("http://localhost:5000/api/points", {
        points: profitPerTap * activeTouches,
      });
    } catch (error) {
      console.error("Error updating points:", error);
    }
  };

  const [showUpgrade, setShowUpgrade] = useState(false);
  const [showCity, setShowCity] = useState(false);
  const [showFriends, setShowFriends] = useState(false);
  const [showQuests, setShowQuests] = useState(false);

  const handleUpgrade = async () => {
    if (totalPoints >= upgradeCost) {
      const newTotalPoints = totalPoints - upgradeCost;
      const newProfitPerTap = profitPerTap + 1;
      const newUpgradeCost = upgradeCost * 2;

      setTotalPoints(newTotalPoints);
      setProfitPerTap(newProfitPerTap);
      setUpgradeCost(newUpgradeCost);

      // Send updated values to the server
      await axios.post("/api/update-game", {
        totalPoints: newTotalPoints,
        coinsPerHour,
        profitPerTap: newProfitPerTap,
        upgradeCost: newUpgradeCost,
      });
    } else {
      alert("Not enough coins!");
    }
  };

  const handleCityUpgrade = (cardId) => {
    const card = cityCards.find((c) => c.id === cardId);
    if (totalPoints >= card.cost) {
      setTotalPoints((prev) => prev - card.cost);
      setCoinsPerHour((prev) => prev + card.profitIncrease);
      setCityCards((prev) =>
        prev.map((c) =>
          c.id === cardId
            ? {
                ...c,
                cost: c.cost * 2,
                currentProfit: c.currentProfit + card.profitIncrease,
              }
            : c
        )
      );
    } else {
      alert("Not enough coins!");
    }
  };

  const handleTaskCompletion = (points) => {
    setTotalPoints((prev) => prev + points);
  };

  const copyReferralLink = () => {
    navigator.clipboard
      .writeText("https://example.com/referral?code=12345")
      .then(() => alert("Referral link copied to clipboard!"))
      .catch(() => alert("Failed to copy referral link."));
  };
  // Handle touch start event
  const handleTouchStart = (e) => {
    // Prevent default to avoid triggering mouse events
    e.preventDefault();
    // Update the number of active touches
    setActiveTouches(e.touches.length);
  };

  // Handle touch end event
  const handleTouchEnd = () => {
    // Reset active touches when fingers are lifted
    setActiveTouches(0);
    handleTap(); // Call handleTap to add points for the final tap
  };

  return (
    <div className="container">
      <div className="header">
        <div className="title">
          <img
            alt="User  Avatar"
            height="30"
            src="https://storage.googleapis.com/a1aa/image/9exyhcIJk21CcCUftOefMhmv3DUg3F1jKSpUUM5tuln9bQlOB.jpg"
            width="30"
          />
          <span>Tank Power</span>
          <i className="fas fa-check-circle verified"></i>
        </div>
        <div className="menu">
          <i className="fas fa-bars"></i>
        </div>
      </div>
      <div className="content">
        <div className="coins">
          <img
            alt="Coin Icon"
            height="30"
            src="https://storage.googleapis.com/a1aa/image/fcyTVLclVByZCC9bEuPpg5tOe052qmtoJH1PWjkR2PuDHUpTA.jpg"
            width="30"
          />
          <span>Coins Per Hour</span>
          <span className="amount">{coinsPerHour.toLocaleString()}</span>
        </div>
        <div className="coins">
          <img
            alt="Coin Icon"
            height="30"
            src="https://storage.googleapis.com/a1aa/image/fcyTVLclVByZCC9bEuPpg5tOe052qmtoJH1PWjkR2PuDHUpTA.jpg"
            width="30"
          />
          <span className="amount">{totalPoints.toLocaleString()}</span>
        </div>
        <div
          className="main-logo"
          onClick={handleTap}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
        ></div>
        <div className="tap-me">Tap Me</div>
      </div>
      <div className="footer">
        <div className="icon" onClick={() => setShowUpgrade(true)}>
          <img
            alt="Improve Icon"
            height="30"
            src="https://storage.googleapis.com/a1aa/image/pne1MKusSu1ve0U5kaSKJtYOtjGIPsUaeyznX1RE4OtLOoSnA.jpg"
            width="30"
          />
          <span> Improve</span>
        </div>
        <div className="icon" onClick={() => setShowCity(true)}>
          <img
            alt="City Icon"
            height="30"
            src="https://storage.googleapis.com/a1aa/image/s2F6Wm8Ub6YVFtrQho8WHw3KoALnE1ZozlhHP7MgVZmxBV6E.jpg"
            width="30"
          />
          <span>City</span>
        </div>
        <div className="icon">
          <img
            alt="Mining Icon"
            height="30"
            src="https://storage.googleapis.com/a1aa/image/QLG5NeZHFVUnNi1nfptDRqe38t8S2gl4ewQRT4f1fG9lwBV6E.jpg"
            width="30"
          />
          <span>Home</span>
        </div>
        <div className="icon" onClick={() => setShowFriends(true)}>
          <img
            alt="Friends Icon"
            height="30"
            src="https://storage.googleapis.com/a1aa/image/reNj7fH1Usrh9UApHjTSNHM42fIDrJ1NZAFNfhHUO2cdcQlOB.jpg"
            width="30"
          />
          <span>Friends</span>
        </div>
        <div className="icon" onClick={() => setShowQuests(true)}>
          <img
            alt="Quests Icon"
            height="30"
            src="https://storage.googleapis.com/a1aa/image/hKfMbON1TevWeoSFc3rW9xECtLRgH4XAt0mHVArwq9FAOoSnA.jpg"
            width="30"
          />
          <span>Quests</span>
        </div>
      </div>
      {showUpgrade && (
        <div className="upgrade-container">
          <div className="back-button" onClick={() => setShowUpgrade(false)}>
            &larr;Back
          </div>{" "}
          <br></br>
          <br></br>
          <div className="upgrade-title">Upgrade Profit Per Tap</div>
          <div className="upgrade-info">
            Current Profit Per Tap: {profitPerTap}
            <br />
            Next Level Cost: {upgradeCost.toLocaleString()} Coins
            <br />
            Next Profit Per Tap: {profitPerTap + 1}
          </div>
          <button className="upgrade-button" onClick={handleUpgrade}>
            Upgrade
          </button>
        </div>
      )}
      {showCity && (
        <div className="city-container">
          <div className="back-button" onClick={() => setShowCity(false)}>
            &larr;Back
          </div>{" "}
          <br></br>
          <br></br>
          <div className="city-title">Upgrade Profit Per Hour</div>
          {cityCards.map((card) => (
            <div className="card" key={card.id}>
              <div className="card-title">Upgrade Card {card.id}</div>
              <div className="card-info">
                Current Profit: {card.currentProfit}
                <br />
                Next Level Cost: {card.cost.toLocaleString()} Coins
                <br />
                Next Profit Increase: {card.profitIncrease}
              </div>
              <button
                className="card-button"
                onClick={() => handleCityUpgrade(card.id)}
              >
                Upgrade
              </button>
            </div>
          ))}
        </div>
      )}
      {showFriends && (
        <div className="friends-container">
          <div className="back-button" onClick={() => setShowFriends(false)}>
            &larr;Back
          </div>{" "}
          <br></br>
          <br></br>
          <div className="friends-title">Refer a Friend</div>
          <div className="friends-info">
            Share this referral link with your friends:
          </div>
          <div className="referral-link">
            https://example.com/referral?code=12345
          </div>
          <button className="copy -button" onClick={copyReferralLink}>
            Copy Link
          </button>
        </div>
      )}
      {showQuests && (
        <div className="quests-container">
          <div className="back-button" onClick={() => setShowQuests(false)}>
            &larr;Back
          </div>{" "}
          <br></br>
          <br></br>
          <div className="quests-title">Complete Tasks to Earn Coins</div>
          {[100, 200, 300].map((points, index) => (
            <div className="task" key={index}>
              <div className="task-title">Task {index + 1}</div>
              <div className="task-info">
                Complete this task to earn {points} coins.
              </div>
              <button
                className="task-button"
                onClick={() => handleTaskCompletion(points)}
              >
                Complete Task
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Home;
