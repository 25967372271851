import React, { useEffect, useState } from "react";
import "./home.css"; // Import your CSS
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Test3 = () => {
  const [userId, setUserId] = useState("");
  const [totalPoints, setTotalPoints] = useState(0);
  const [coinsPerHour, setCoinsPerHour] = useState(0);
  const [profitPerTap, setProfitPerTap] = useState(1);
  const [upgradeCost, setUpgradeCost] = useState(0);
  const [cityCards, setCityCards] = useState([]);
  const [taskCards, setTaskCards] = useState([]);
  const [showQuests, setShowQuests] = useState(false);
  const [activeTouches, setActiveTouches] = useState(0);
  const [showUpgrade, setShowUpgrade] = useState(false);
  const [showCity, setShowCity] = useState(false);
  const [showFriends, setShowFriends] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const userId = sessionStorage.getItem("userId");
    if (!userId) {
      alert("User ID not found. Redirecting to login.");
      navigate("/login");
    } else {
      setUserId(userId);
      fetchUserData(userId);
      fetchCityCards();
      fetchTaskCards();
    }
  }, [navigate]);

  const fetchUserData = async (userId) => {
    try {
      const response = await axios.post(
        "https://api.tankpower.online/api/userdata",
        { userId }
      );
      if (response.data && response.data.length > 0) {
        const userData = response.data[0];
        setTotalPoints(userData.totalPoints);
        setCoinsPerHour(userData.coinsPerHour);
        setProfitPerTap(userData.profitPerTap);
        setUpgradeCost(userData.upgradeCost);
      } else {
        console.warn("User data is empty or not returned correctly.");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const fetchCityCards = async () => {
    try {
      const response = await fetch(
        "https://api.tankpower.online/api/city-cards"
      );
      const data = await response.json();
      setCityCards(data);
    } catch (error) {
      console.error("Error fetching city cards:", error);
    }
  };

  const fetchTaskCards = async () => {
    try {
      const response = await fetch(
        "https://api.tankpower.online/api/get-tasks"
      );
      const data = await response.json();
      setTaskCards(data || []);
    } catch (error) {
      console.error("Error fetching task cards:", error);
    }
  };

  const handleLogout = () => {
    sessionStorage.removeItem("userId");
    sessionStorage.removeItem("expiryTime");
    window.location.href = "/login";
  };

  const formatNumber = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleTouchStart = (e) => {
    e.preventDefault();
    setActiveTouches(e.touches.length);
  };

  const handleTouchEnd = () => {
    setActiveTouches(0);
    // handleLogoClick();
  };

  const handleUpgrade = async () => {
    if (totalPoints >= upgradeCost) {
      try {
        const newTotalPoints = totalPoints - upgradeCost;
        const newProfitPerTap = profitPerTap + 1;
        const newUpgradeCost = upgradeCost * 2;

        const response = await axios.post(
          "https://api.tankpower.online/api/postUpdatePerClick",
          { newTotalPoints, newProfitPerTap, newUpgradeCost, userId }
        );

        if (response.status === 200) {
          setTotalPoints(newTotalPoints);
          setProfitPerTap(newProfitPerTap);
          setUpgradeCost(newUpgradeCost);
          alert("Upgrade successful!");
        } else {
          alert("Failed to upgrade. Please try again.");
        }
      } catch (error) {
        console.error("Error upgrading:", error);
        alert("An error occurred during the upgrade.");
      }
    } else {
      alert("Not enough points for the upgrade.");
    }
  };

  const copyReferralLink = () => {
    navigator.clipboard
      .writeText("http://example.com/referral?code=12345")
      .then(() => alert("Referral link copied to clipboard!"))
      .catch(() => alert("Failed to copy referral link."));
  };

  return (
    <div className="home-container">
      {/* Render components as needed */}
      <h1>Welcome, {userId}</h1>
      <p>Total Points: {formatNumber(totalPoints)}</p>
      <p>Coins Per Hour: {formatNumber(coinsPerHour)}</p>
      <p>Profit Per Tap: {formatNumber(profitPerTap)}</p>
      <p>Upgrade Cost: {formatNumber(upgradeCost)}</p>

      {/* Buttons and interactions */}
      <button onClick={handleUpgrade}>Upgrade</button>
      <button onClick={handleLogout}>Logout</button>
      <button onClick={copyReferralLink}>Copy Referral Link</button>
    </div>
  );
};

export default Test3;
